import React from 'react'

import { saveObj, getItem } from '../../store/api.js'

import InputText    from '../_atoms/InputText'
import Label        from '../_atoms/Label'
import Helper       from '../../config/helper'
import InputImage   from '../_molecules/InputImage'

export default class FormCategoria extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            model:this.props.model,
            id:this.props.id,
            data:{
              items:{},
              relations:{}
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this)
        this.setModelData = this.setModelData.bind(this)
    }

    async componentDidMount() {
    
        const item = await getItem(this.state.model, this.state.id)

        if (item.data && item.data != '') {
            this.setState({ data: {items: item.data, relations: {Variacao: item.data.variacao} }})
        }
    }

    async handleSubmit(event) {
        event.preventDefault()
    
        try {
          await saveObj(this.state.data, this.state.model)
          Helper.addNotification('success', 'Tudo certo por aqui :)', 'Mudanças feitas foram salvas com sucesso.', 'top-right')
          window.history.back()
        } catch (e) {
          Helper.addNotification('danger', 'Alguma coisa deu errado :(', 'Não foi possivel salvar as informações enviadas.', 'top-right')
        }
    }

    setModelData = (name, value) => {
        if (value !== undefined) {
            let data = this.state.data
            data.items[name] = value

            this.setState({ data })
        }
    }

    render() {
        const atual = this.state.data.items
        return (
            <form onSubmit={this.handleSubmit}>
                <div className='row'>
                    <div className="col-md-6">

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="nome"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.nome : ""}
                            />
                            <Label nome="nome" label="Nome o opcional"/>
                        </div>

                        <div className="form-floating mb-15px" style={{width:"30%", float:"left"}}>
                            <InputImage 
                                nome="id_gb_imagem"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual && atual.imagem ? atual.imagem.imagem : null}
                                required={true}
                            />
                        </div>
                        
                    </div>

                    <div className="col-md-6">

                        <div className="form-floating mb-15px">
                            <InputText
                                nome="valor"
                                setFomrData={this.setModelData}
                                dadosIniciais={atual ? atual.valor : ""}
                            />
                            <Label nome="valor" label="Valor" textArea={true}/>
                        </div>

                    </div>

                    
                </div>

                <div className='row'>
                    <div className="col-md-2 offset-md-10">
                        <button type="submit" style={{float: 'right'}} className="btn btn-success btn-block btn-lg">Salvar</button>
                    </div>
                </div>
            </form>
        )
    }
}
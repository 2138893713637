import React from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Moment from 'moment'

import Lottie from "lottie-react"
import lottiejson from "../assets/relatorios-trabalhando.json"

import TopPage from '../components/_organisms/TopPage'

export default class EditarNegocio extends React.Component {
    constructor(props) {
        super(props)

        const user = JSON.parse(localStorage.getItem('user')).data
        this.state = {
            user: user,
            model:this.props.info.match.params.model,
            tabActive: "tab-1",
            isLoading: false,
            startDate: null,
            endDate: null,
            enable:false
        }

        this.handleTabs = this.handleTabs.bind(this)
        this.handleDateApplyEvent = this.handleDateApplyEvent.bind(this)
		this.hadleFilterDate = this.hadleFilterDate.bind(this)
        this.gerarRelatorio = this.gerarRelatorio.bind(this)
    }

    async componentDidMount() {
            const startDate = Moment().locale('pt-br')
            const endDate = Moment().locale('pt-br')
            const gap = endDate.diff(startDate, 'days')
            
            const currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
            const prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
            
            this.setState({dateRange: {
                currentWeek: currentWeek,
                prevWeek: prevWeek,
                gap:gap,
                startDate:startDate.format('YYYY-MM-DD'), 
                endDate:endDate.format('YYYY-MM-DD')
            }})
    }

    gerarRelatorio(){
        window.location.href = "/VendasProdutos?di="+this.state.dateRange.startDate+"&df="+this.state.dateRange.endDate
    }

    handleDateApplyEvent(e, picker) {
    
        var startDate = picker.startDate
        var endDate = picker.endDate
        var gap = endDate.diff(startDate, 'days')
        
        var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
        var prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
        
        this.setState({dateRange: {
            currentWeek: currentWeek,
            prevWeek: prevWeek,
            gap:gap,
            startDate:startDate.format('YYYY-MM-DD'), 
            endDate:endDate.format('YYYY-MM-DD')
        }})
    }

    hadleFilterDate(e, filter){
        e.preventDefault()

        const today = Moment()
        let priorDate = Moment(new Date().setDate(new Date().getDate() - 1))

        this.setState({filterDates: filter})

        if (filter === "today"){
            this.handleDateApplyEvent(null, {startDate:today, endDate:today})
        }

        if (filter === "yesterday"){
            this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
        }

        if (filter === "lastweek"){
            priorDate = Moment(new Date().setDate(new Date().getDate() - 7))
            this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
        }

        if (filter === "last30"){
            priorDate = Moment(new Date().setDate(new Date().getDate() - 30))
            this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
        }

        if (filter === "thismonth"){
            priorDate = Moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
            this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
        }
    }
 
    handleTabs(e){
        e.preventDefault()

        this.setState({tabActive: e.target.dataset.tab})
    }

    render() {
        var initialDatePicker = {
            startDate: Moment().subtract(7, 'days'),
            endDate: Moment(),
            ranges: {
            'Hoje': [Moment().toDate(), Moment().toDate()],
            'Ontem': [
                Moment().subtract(1, 'days').toDate(),
                Moment().subtract(1, 'days').toDate(),
            ],
            'Últimos 7 dias': [
                Moment().subtract(6, 'days').toDate(),
                Moment().toDate(),
            ],
            'Últimos 30 Dias': [
                Moment().subtract(29, 'days').toDate(),
                Moment().toDate(),
            ],
            'Mês atual': [
                Moment().startOf('month').toDate(),
                Moment().endOf('month').toDate(),
            ],
            'Mês anterior': [
                Moment().subtract(1, 'month').startOf('month').toDate(),
                Moment().subtract(1, 'month').endOf('month').toDate(),
            ],
            },
        }
        
        return (
            <div>
                <TopPage model="Relatórios do meu negócio" new={true}>
                </TopPage>

                <p className="lead">
                    Tenha uma visão clara e detalhada do seu negócio com nossos relatórios completos. Acompanhe vendas, controle de produtos e insumos, estoque atualizado, desempenho do delivery e dados financeiros da conta digital. Tudo em um só lugar, para decisões mais rápidas e precisas!
                </p>

                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a href="#tab-1" data-tab="tab-1" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive === "tab-1" ? "nav-link active" : "nav-link"}>
                        VENDAS
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-2" data-tab="tab-2" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive === "tab-2" ? "nav-link active" : "nav-link"}>
                        PRODUTOS
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-3" data-tab="tab-3" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive === "tab-3" ? "nav-link active" : "nav-link"}>
                        INSUMOS
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-4" data-tab="tab-4" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive === "tab-4" ? "nav-link active" : "nav-link"}>
                        STOCK
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-5" data-tab="tab-5" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive === "tab-5" ? "nav-link active" : "nav-link"}>
                        DELIVERY
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#tab-6" data-tab="tab-6" onClick={(event) => this.handleTabs(event)} data-bs-toggle="tab" className={this.state.tabActive === "tab-6" ? "nav-link active" : "nav-link"}>
                        FINANCEIRO
                        </a>
                    </li>
                </ul>

                <div className="tab-content panel p-5 rounded-0 rounded-bottom">
                    <div className={this.state.tabActive === "tab-1" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-1">
                        <p>Selecione o periodo e o <b>tipo de relatório que deseja:</b></p> 

                        <div className='row'>
                            <div style={{padding:'20px 0'}}>
                                <div className="btn-group mb-3"  style={{float:"left"}}>
                                    <button className={this.state.filterDates === "today" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "today")}>Hoje</button>
                                    <button className={this.state.filterDates === "yesterday" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "yesterday")}>Ontem</button>
                                    <button className={this.state.filterDates === "lastweek" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "lastweek")}>Últ. 7 dias</button>
                                    <button className={this.state.filterDates === "last30" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "last30")}>Últ. 30 Dias</button>
                                    <button className={this.state.filterDates === "thismonth" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "thismonth")}>Mês atual</button>
                                </div>

                                <div className="d-sm-flex align-items-center mb-3" style={{float:"right"}}>
                                    <DateRangePicker initialSettings={initialDatePicker} onApply={this.handleDateApplyEvent}>
                                        <button className="btn btn-white me-2 text-truncate">
                                            <i className="fa fa-calendar fa-fw text-opacity-50 ms-n1 me-1"></i> 
                                            <span>{this.state.dateRange && (this.state.dateRange.currentWeek)}</span>
                                            <b className="caret ms-1 opacity-5"></b>
                                        </button>
                                    </DateRangePicker>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                           
                            <div className="col-xl-4 col-md-6">
                                <div className="widget widget-stats" style={{border: "1px solid #ccc"}}>
                                    <div className="stats-content">
                                        <div className="stats-title">Relatorio de vendas</div>
                                        <div className="stats-number" style={{color:"#0043ff"}}>
                                            <input className="form-check-input" name="b_ativo" type="radio"  onChange={() => this.setState({enable:!this.state.enable})}  />
                                           
                                            Vendas por categoria
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <button 
                                type="button" 
                                className="btn btn-primary me-1 mb-1 float-end" 
                                onClick={() => this.gerarRelatorio()}
                                disabled={!this.state.enable}
                            >
                               
                                Gerar relatório
                            </button>
                        </div>

                    </div>

                    <div className={this.state.tabActive === "tab-2" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-2">
                        <div className="card border-0" style={{padding:"15px"}}>
                            <Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
                            <h1 style={{textAlign:"center"}}>Ops! Estamos trabalhando duro para entregar tudo isso. </h1>
                            <p  style={{textAlign:"center"}}>Os relatórios estarão disponíveis em breve. Desculpa pelo transtorno, estamos trabalhando noite e dia para que eles cheguem até você o quanto antes!</p>
                        </div>
                    </div>

                    <div className={this.state.tabActive === "tab-3" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-3">
                        <div className="card border-0" style={{padding:"15px"}}>
                            <Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
                            <h1 style={{textAlign:"center"}}>Ops! Estamos trabalhando duro para entregar tudo isso. </h1>
                            <p  style={{textAlign:"center"}}>Os relatórios estarão disponíveis em breve. Desculpa pelo transtorno, estamos trabalhando noite e dia para que eles cheguem até você o quanto antes!</p>
                        </div>
                    </div>

                    <div className={this.state.tabActive === "tab-4" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-4">
                        <div className="card border-0" style={{padding:"15px"}}>
                            <Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
                            <h1 style={{textAlign:"center"}}>Ops! Estamos trabalhando duro para entregar tudo isso. </h1>
                            <p  style={{textAlign:"center"}}>Os relatórios estarão disponíveis em breve. Desculpa pelo transtorno, estamos trabalhando noite e dia para que eles cheguem até você o quanto antes!</p>
                        </div>
                    </div>

                    <div className={this.state.tabActive === "tab-5" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-5">
                        <div className="card border-0" style={{padding:"15px"}}>
                            <Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
                            <h1 style={{textAlign:"center"}}>Ops! Estamos trabalhando duro para entregar tudo isso. </h1>
                            <p  style={{textAlign:"center"}}>Os relatórios estarão disponíveis em breve. Desculpa pelo transtorno, estamos trabalhando noite e dia para que eles cheguem até você o quanto antes!</p>
                        </div>
                    </div>

                    <div className={this.state.tabActive === "tab-6" ? "tab-pane fade active show" : "tab-pane fade"} id="tab-6">
                        <div className="card border-0" style={{padding:"15px"}}>
                            <Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
                            <h1 style={{textAlign:"center"}}>Ops! Estamos trabalhando duro para entregar tudo isso. </h1>
                            <p  style={{textAlign:"center"}}>Os relatórios estarão disponíveis em breve. Desculpa pelo transtorno, estamos trabalhando noite e dia para que eles cheguem até você o quanto antes!</p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
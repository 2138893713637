import React from 'react'

import { getItem } from './../store/api.js';

export default class Detalhe extends React.Component {
    constructor(props) {
		super(props);

		const user = JSON.parse(localStorage.getItem('user')).data
		this.state = {
			user: user,
			brand:user.brand ? user.brand : "",
			model:"Comanda",
            comanda: null
			
		}

        this.fetchData = this.fetchData.bind(this)
	}

	fetchData(){
        const id = this.props.id

        if (id) {
            getItem('Comanda',id).then(result => {
                this.setState({ comanda: result.data })
            })
        }
	}

    getData(date){
        const dt = new Date(date)
        return dt.getDate()+"/"+(dt.getMonth()+1)+"/"+dt.getFullYear()
    }

    getHora(date){
        const dt = new Date(date)
        return dt.getHours()+":"+dt.getMinutes()
    }

    componentDidMount() {
		this.fetchData()
	}

    getTotalPedido(){
        const comanda = this.state.comanda
        let total = 0
        
        if (comanda) {
            comanda.pedidos.forEach(pedido => {
                pedido.produtos.forEach(produto => {
                    total += parseFloat(produto.valor) * parseFloat(produto.quantidade)

                    produto.adicionais.forEach(adicionais => {
                        total += parseFloat(adicionais.valor) * parseFloat(adicionais.quantidade)
                    })
                })
            })
        }
        return total
    }

    render(){
        const comanda = this.state.comanda
        const entrega = comanda && comanda.entrega && comanda.entrega.length > 0 ? comanda.entrega[0] : comanda ? comanda.entrega : null
        const dthora = comanda ? new Date(comanda.created_at) : new Date()
        const subtotal = comanda && this.getTotalPedido()
        const taxaServico = comanda && comanda.pagamentos.length > 0 && comanda.pagamentos[0].b_taxa === 1 ? parseFloat((subtotal*comanda.pagamentos[0].valor_taxa) / 100) : 0
        const taxaEntrega = comanda && comanda.entrega && comanda.entrega.zonaEntrega ? parseFloat(comanda.entrega.zonaEntrega.valor) : 0

        return (
			<div>
            
                {this.state.comanda && (
                    <div className="row gx-4">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-md-9">
                                    {comanda.b_delivery === 1 &&
                                        <span className="badge border border-primary text-primary px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                            <i className="fa fa-truck-fast fs-9px fa-fw me-5px"></i> Delivery 
                                        </span>
                                    }

                                    {comanda.b_takeaway === 1 &&
                                        <span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                            <i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda 
                                        </span>
                                    }

                                    {comanda.b_delivery === 0 && comanda.b_takeaway === 0 && comanda.mesa.b_comanda === 0 &&
                                        <span className="badge border border-pink text-pink px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                            <i className="fa fa-utensils fs-9px fa-fw me-5px"></i> Mesa
                                        </span>
                                    }

                                    {comanda.b_delivery === 0 && comanda.b_takeaway === 0 && comanda.mesa.b_comanda === 1 &&
                                        <span className="badge border border-purple text-purple px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                            <i className="fa fa-bag-shopping fs-9px fa-fw me-5px"></i> Comanda
                                        </span>
                                    }

                                    {comanda.pagamentos.length > 0 ? 
                                        <span className="badge border border-success text-success px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{marginRight:"5px"}}>
                                            <i className="fa fa-circle fs-9px fa-fw me-5px"></i>
                                            Pago
                                        </span>
                                    :
                                        <span className="badge border border-warning text-warning px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center" style={{marginRight:"5px"}}>
                                            <i className="fa-solid fa-clock fs-9px fa-fw me-5px"></i>
                                            Pendente
                                        </span>
                                    }
                                </div>
                                <div className="col-md-3 text-end">
                                    {dthora.getDate()+"/"+(dthora.getMonth()+1)+"/"+dthora.getFullYear()} - {dthora.getHours()+":"+dthora.getMinutes()}
                                </div>
                            </div>
                            <div className="card border-0">
                                <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
                                    <div className="col-lg-6 d-flex align-items-center">
                                        <i className="fa fa-shopping-bag fa-lg me-2 text-gray text-opacity-50"></i>
                                        Produtos ({comanda.pedidos.reduce( (a,b) => a += b.produtos.length, 0)})
                                    </div>

                                    <div className="col-lg-3 m-0 ps-lg-3">
                                        Valor un.
                                    </div>

                                    <div className="col-lg-3 m-0 ps-lg-3 text-end">
                                        Total
                                    </div>
                                </div>

                                <div className="card-body text-dark fw-bold">
                                    {comanda.pedidos.map((item) => 
                                        item.produtos.map((produto, idx) => 
                                            <>
                                                <div className="row align-items-center">
                                                    <div className="col-lg-6 d-flex align-items-center">
                                                        <div className="h-65px w-65px d-flex align-items-center justify-content-center position-relative">
                                                            <div className="w-50px h-50px bg-light d-flex align-items-center justify-content-center" style={{backgroundImage:"url(https://grubupload.s3.amazonaws.com/"+produto.imagem+")", backgroundSize:"cover", borderRadius:"20%"}}></div>
                                                            <span className="w-20px h-20px p-0 d-flex align-items-center justify-content-center badge bg-primary text-white position-absolute end-0 top-0 fw-bold fs-12px rounded-pill mt-n2 me-n2">{produto.quantidade}</span>
                                                        </div>
                                                        <div className="ps-3 flex-1">
                                                            <div>{produto.nome}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 m-0 ps-lg-3">R$ {produto.valor} x {produto.quantidade}</div>
                                                    <div className="col-lg-3 text-dark fw-bold m-0 text-end">R$ {produto.valor * produto.quantidade}</div>
                                                </div>
                                                {produto.adicionais.length > 0 && (
                                                    <div style={{marginLeft: "65px"}}>
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-6 d-flex align-items-center">
                                                                <div className="ps-3 flex-1">
                                                                    <div className="text-muted"><b>ADICIONAIS</b></div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-3 m-0 ps-lg-3"></div>
                                                            <div className="col-lg-3 m-0 ps-lg-3 text-end"></div>
                                                        </div>

                                                        {produto.adicionais.map((opcional) =>
                                                            <div className="row align-items-center">
                                                                <div className="col-lg-6 d-flex align-items-center">
                                                                    <div className="ps-3 flex-1">
                                                                        <div>{opcional.nome}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-3 m-0 ps-lg-3">R$ {opcional.valor} x {opcional.quantidade}</div>
                                                                <div className="col-lg-3 text-dark fw-bold m-0 text-end">R$ {opcional.valor * opcional.quantidade}</div>
                                                            </div>
                                                        )}
                                                   </div>
                                                )}
                                                <hr className="my-1" />
                                            </>
                                        )
                                    )}
                                </div>
                            </div>

                            <div className="card border-0" style={{marginRight:"15px", marginBottom:"30px"}}>
                                <div className='row'>
                                    <div className="col-md-4 text-end"><b>Subtotal:</b></div>
                                    <div className="col-md-3 offset-md-5 text-end"><b>{subtotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                                </div>

                                {comanda.b_delivery === 1 ?(
                                    <div className='row'>
                                        <div className="col-md-4 text-end"><b>Taxa de entrega:</b></div>
                                        <div className="col-md-3 offset-md-5 text-end"><b>{taxaEntrega.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                                    </div>
                                ):(
                                    <div className='row'>
                                        <div className="col-md-4 text-end"><b>Taxa de serviço:</b></div>
                                        <div className="col-md-3 offset-md-5 text-end"><b>{taxaServico.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                                    </div>
                                )}

                                {this.props.comissao ? (
                                    <>
                                        <div className='row'>
                                            <div className='col-md-4 text-end'><b>Comissão de venda GRUB®:</b></div>
                                            <div className='col-md-3 offset-md-5 text-end'><b>{parseFloat(this.props.comissao).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-md-4 text-end"><b>Total:</b></div>
                                            <div className="col-md-3 offset-md-5 text-end" style={{color:"green"}}><b>{((subtotal+taxaServico+taxaEntrega) + parseFloat(this.props.comissao)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></div>
                                        </div>

                                        <div className='row mt-3 alert alert-primary'>
                                            <div className='col-md-12 text-end'>Com a GRUB® você economizou <b>{((subtotal+taxaServico+taxaEntrega) * 0.145).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</b> de taxas abusivas em este pedido.</div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='row'>
                                        <div className="col-md-4 text-end"><b>Total:</b></div>
                                        <div className="col-md-3 offset-md-5 text-end" style={{color:"green"}}><b>{(subtotal+taxaServico+taxaEntrega).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</b></div>
                                    </div>
                                )}
                             
                               
                            </div>

                            {entrega && (
                                <div className="card border-0 mb-4">
                                    <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">Informação de entrega:</div>
                                    <div className="card-body fw-bold">
                                        <div><b>NOME:</b> {entrega && entrega.nome}</div>
                                        <div><b>TELEFONE:</b> {entrega.telefone}</div>
                                        <div><b>CEP:</b> {entrega.cep}</div>
                                        <div><b>ENDEREÇO:</b>{entrega.rua+", "+entrega.numero+", "+ entrega.bairro+", "+ entrega.cidade}</div>
                                    </div>
                                </div>
                            )}

                            <div className="card border-0">
                                <div className="card-header bg-none p-3 h6 m-0 d-flex align-items-center">
                                    <i className="fa fa-credit-card fa-lg me-2 text-gray text-opacity-50"></i>Dados do pagamento:
                                </div>

                                <div className="timeline">
                                {comanda.pagamentos.map((item) => 
                                    <div className="timeline-item">
                                        <div className="timeline-time">
                                            <span className="date">{this.getData(item.created_at)}</span>
                                            <span className="time">{this.getHora(item.created_at)}</span>
                                        </div>
                                        <div className="timeline-icon">
                                            <a href="#/">&nbsp;</a>
                                        </div>
                                        <div className="timeline-content" style={{background: "#EDEDED"}}>
                                            <div className="timeline-body">
                                                <div className='row'>
                                                    <div className='col-md-6'>Tipo Pagamento:</div>
                                                    <div className='col-md-6 text-end'>Pagamento Parcial:</div>
                                                </div>
                                                <div className='row mb-5'>
                                                    {item.tipo === "debito" && (
                                                        <div className='col-md-6' style={{color:"#7b2cff", textTransform:"uppercase"}}><b>débito</b></div>
                                                    )}

                                                    {item.tipo === "credito" && (
                                                        <div className='col-md-6' style={{color:"#0043ff", textTransform:"uppercase"}}><b>crédito</b></div>
                                                    )}

                                                    {item.tipo === "pix" && (
                                                        <div className='col-md-6' style={{color:"green", textTransform:"uppercase"}}><b>{item.tipo}</b></div>
                                                    )}

                                                    {item.tipo === "dinheiro" && (
                                                        <div className='col-md-6' style={{color:"green", textTransform:"uppercase"}}><b>{item.tipo}</b></div>
                                                    )}
                                                    
                                                    
                                                    <div className='col-md-6 text-end' style={{color:"green"}}><b>{parseFloat(item.valor).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</b></div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 )}
                                </div>

                            </div>
                            
                        </div>

                    </div>
                )}
            </div>
        )
    }

}
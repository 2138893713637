import React from 'react'

import { saveObj } from './../store/api.js'

export default class Detalhe extends React.Component {
    constructor(props) {
        super(props)

        const lsUser = localStorage.getItem('user')
		const user = lsUser ? JSON.parse(localStorage.getItem('user')).data : null

        this.state = {
            user: user,
			brand:user ? user.brand : null,
            resumo: null
        }

        this.fetchData = this.fetchData.bind(this)
    }

    async fetchData(){
        const resumo = await saveObj( {loja_id: this.state.user.loja.id, tranferencia_id:this.props.id}, 'asaas/detalheTranferencia' )
        this.setState({resumo:resumo.data})
    }

    componentDidMount(){
        this.fetchData()
    }

    render(){
        return (
            <div>

                {this.state.resumo && (
                    <div>
                        <div className="row align-items-center my-3">
                            <div className="col">
                                <div className="d-flex align-items-center">
                                <img 
                                    src="https://grubicons.s3.us-east-1.amazonaws.com/transferencia-extrato.png" 
                                    alt="Transferência" 
                                    className="me-3" 
                                    style={{ width: "40px", height: "40px" }}
                                />
                                <div>
                                    <h5 className="mb-1">Transferência {this.state.resumo.operationType}</h5>
                                    <p className="text-muted mb-0">
                                    {new Date(this.state.resumo.effectiveDate).toLocaleString('pt-BR')}
                                    </p>
                                </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <a 
                                href={this.state.resumo.transactionReceiptUrl} 
                                className="btn btn-white" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                >
                                <i className="bi bi-download me-2"></i>
                                    Ver comprovante
                                </a>
                            </div>
                        </div>  
                        <div className="container mt-3">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <p className="fw-bold mb-1">Valor transferido:</p>
                                    <h2 className="text-success fw-bold">
                                    <b>{new Intl.NumberFormat('pt-BR', { 
                                        style: 'currency', 
                                        currency: 'BRL' 
                                    }).format(parseFloat(this.state.resumo.value))}</b>
                                    </h2>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-12">
                                <p className="fw-bold mb-1">Situação:</p>
                                    {this.state.resumo.status === 'DONE' ? 
                                        <span className="text-success fw-bold">Confirmado</span> : 
                                        <span className="text-danger fw-bold">Não confirmado</span>}
                                </div>
                            </div>
                            
                            <div className="row mb-3">
                                <div className="col-12">
                                <p className="fw-bold mb-1">ID / Transação {this.state.resumo.operationType}:</p>
                                {this.state.resumo.id}
                                </div>
                            </div>
                            
                            <div className="row mb-3">
                                <div className="col-12">
                                <p className="fw-bold mb-1">Descrição:</p>
                                {this.state.resumo.description}
                                </div>
                            </div>
                        </div>
                        <h3 className="container mt-4 mb-3">Informação do destinatário:</h3>
                        <div className="container row mb-3">
                            <div className="col-12">
                                <p className="fw-bold mb-1">CPF/CNPJ:</p>
                                {this.state.resumo.bankAccount.cpfCnpj}
                            </div>
                        </div>
                        <div className="container row mb-3">
                            <div className="col-12">
                                <p className="fw-bold mb-1">Titular:</p>
                                {this.state.resumo.bankAccount.ownerName}
                            </div>
                        </div>
                        <div className="container row mb-3">
                            <div className="col-12">
                                <p className="fw-bold mb-1">Banco:</p>
                                {this.state.resumo.bankAccount.bank.name}
                            </div>
                        </div>
                    </div>
                )}
            
            </div>
        )
    }

}
import React from 'react'

import { saveObj, getList, getStatusLoja, solicitaPIX } from './../store/api.js'
import { Link } from 'react-router-dom'
import TopPage from '../components/_organisms/TopPage'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Moment from 'moment'

import Offcanvas from 'react-bootstrap/Offcanvas'

import Detalhe from './../pages/detalhe.js'
import DetalheTransferencia from './../pages/detalheTransaferencia.js'

import Lottie from "lottie-react"
import lottiejson from "../assets/error-grub.json"
import lottieplaceholder from "../assets/meu-dinheiro.json"

import InputText    from '../components/_atoms/InputText'
import CurrencyInput from 'react-currency-masked-input'
import Label        from '../components/_atoms/Label'

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

export default class Banco extends React.Component {
    constructor(props) {
		super(props)
        const lsUser = localStorage.getItem('user')
		const user = lsUser ? JSON.parse(localStorage.getItem('user')).data : null

        this.state = {
            user: user,
			brand:user ? user.brand : null,
			metas: null,
            dateRange: null,
            dadosDaConta: null,
            tabs: [{chave: 'extrato', valor: 'EXTRATO DA CONTA'},{chave: 'recebiveis', valor: 'RECEBÍVEIS'},{chave: 'transferencia', valor: 'TRANSFERÊNCIA'}],
            filtro: 'extrato',
            filtrodt:{dtInicio: Moment(new Date().setDate(new Date().getDate() - 30)), dtFim: Moment()},
            filterDates: "filtro3",
            list:[],
            resumo: null,
            modalPix: false,
		}
		
        this.fetchData = this.fetchData.bind(this)
        this.handleDateApplyEvent = this.handleDateApplyEvent.bind(this)
        this.aplicaFiltro = this.aplicaFiltro.bind(this)
        this.toggleModalPix = this.toggleModalPix.bind(this)
        this.setValor = this.setValor.bind(this)
        this.enviarPix = this.enviarPix.bind(this)
	}

    toggleModalPix(e) {
		e.preventDefault()
		this.setState({modalPix: !this.state.modalPix})
	}

    setValor = (e, value) => {
       this.setState({valorPIX: value})
    }

    enviarPix(){
        solicitaPIX(this.state.valorPIX)
    }

	async fetchData(startDate, endDate, aba = 'asaas/extrato'){
        const list = await saveObj( {loja_id: this.state.user.loja.id, di:startDate, df:endDate}, aba )
        const resumo = await saveObj( {loja_id: this.state.user.loja.id}, 'asaas/resumo' )
        const dadosDaConta = await getList("AsaasAcount")
        const statusLoja = await getStatusLoja()
        const pagamentos = []

        const items = list.data.data

        items.forEach(element => {
            pagamentos.push( element.paymentId )
        })

        const pedidos = await getList('comandas-pagamentos', {pagamentos: pagamentos})
        const pedArr = pedidos

        items.forEach(element => {
            const comissoes = items.filter((i) => i.paymentId === element.paymentId && i.type !== "PAYMENT_RECEIVED")
            element.pagamento = pedArr.find((i) => i.id_externo === element.paymentId)
            element.comissao = comissoes.reduce((total, i) => total += i.value ,0 )
        })

        this.setState({list:items, resumo:resumo.data, dadosDaConta:dadosDaConta, statusLoja: statusLoja.data})
	}

    async aplicaFiltro(e){
		e.preventDefault()

		const t = e.target
		const id = t.dataset.filter 
        let aba = 'asaas/extrato'
        const startDate = this.state.filtrodt.dtInicio
        const endDate = this.state.filtrodt.dtFim

        this.setState({list:[],filtro: id})

        if(id === 'recebiveis') aba = 'asaas/consultaCobranca'
        if(id === 'transferencia') aba = 'asaas/tranferencia'

        await this.fetchData(startDate, endDate, aba)
	}
    
    componentDidMount() {
        const startDate = Moment(new Date().setDate(new Date().getDate() - 30))
        const endDate = Moment()
		const gap = endDate.diff(startDate, 'days')
		
		const currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
		const prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
		
		this.setState({dateRange: {
			currentWeek: currentWeek,
			prevWeek: prevWeek,
			gap:gap
		}, filtrodt:{dtInicio: startDate, dtFim: endDate }})

		this.fetchData(startDate, endDate)
	}

    formatDate(date){
        const dt = new Date(date)

        return dt.getDate()+"/"+(dt.getMonth()+1)+"/"+dt.getFullYear()
    }

    handleDateApplyEvent(e, picker) {
		var startDate = picker.startDate
		var endDate = picker.endDate
		var gap = endDate.diff(startDate, 'days')
		
		var currentWeek = startDate.format('D MMMM YYYY') + ' - ' + endDate.format('D MMMM YYYY')
		var prevWeek = Moment(startDate).subtract('days', gap).format('D MMMM') + ' - ' + Moment(startDate).subtract('days', 1).format('D MMMM YYYY')
		
		this.setState({dateRange: {
			currentWeek: currentWeek,
			prevWeek: prevWeek,
			gap:gap
		}})

		this.fetchData(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'))
	}

    hadleFilterDate(e, filter){
		e.preventDefault()

		const today = Moment()
		let priorDate = Moment(new Date().setDate(new Date().getDate() - 7))

		this.setState({filterDates: filter})

		if (filter === "filtro2") priorDate = Moment(new Date().setDate(new Date().getDate() - 15))
		if (filter === "filtro3") priorDate = Moment(new Date().setDate(new Date().getDate() - 30))

        this.setState({filtrodt:{dtInicio: priorDate, dtFim: today}})

        this.handleDateApplyEvent(null, {startDate:priorDate, endDate:today})
	}

    calcula(item, i, arr){
        return parseFloat(item.value) - parseFloat(arr[i+1].value)
    }

    getSaldo(i, arr) {
        return parseFloat(arr[i-2].balance)
    }

    handleDetalhe(e, item){
		e.preventDefault()
		this.setState({detalheId:item.pagamento.id_comanda, comissao: item.comissao ? item.comissao : null, showCanvas:!this.state.showCanvas})
	}

    handleDetalheTransferencia(e, item){
        e.preventDefault()
		this.setState({transferencia:item.transferId, showCanvasTransferencia:!this.state.showCanvasTransferencia})
    }

    render(){
        var initialDatePicker = {
            startDate: Moment().subtract(7, 'days'),
            endDate: Moment(),
            ranges: {
              'Hoje': [Moment().toDate(), Moment().toDate()],
              'Ontem': [
                Moment().subtract(1, 'days').toDate(),
                Moment().subtract(1, 'days').toDate(),
              ],
              'Últimos 7 dias': [
                Moment().subtract(6, 'days').toDate(),
                Moment().toDate(),
              ],
              'Últimos 30 Dias': [
                Moment().subtract(29, 'days').toDate(),
                Moment().toDate(),
              ],
              'Mês atual': [
                Moment().startOf('month').toDate(),
                Moment().endOf('month').toDate(),
              ],
              'Mês anterior': [
                Moment().subtract(1, 'month').startOf('month').toDate(),
                Moment().subtract(1, 'month').endOf('month').toDate(),
              ],
            },
          }

        return (
            <>
                {this.state.dadosDaConta && this.state.dadosDaConta.length > 0 ? (
                    <div>
                        <TopPage titulo="Meu dinheiro">
                            <p className="lead">
                            Com o Meu Dinheiro, você tem controle total sobre seu saldo e todas as compras feitas na GRUB®. <br></br>
                            Consulte seus créditos, veja o histórico de transações e use seu saldo com facilidade.
                            </p>
                        </TopPage>
                        
                        <div className="row">
                            {this.state.resumo && (
                                <>
                                    <div className="col-xl-4 col-md-6">
                                        <div className="widget widget-stats ">
                                            <div className="stats-content">
                                                <div className="stats-title">Minha conta</div>

                                                {this.state.dadosDaConta && (
                                                    <div className="stats-number" style={{fontSize:"20px", margin:"0"}}>
                                                        <b style={{color:"#0043ff"}}>Conta:</b> {this.state.dadosDaConta[0].account} - {this.state.dadosDaConta[0].account_digit}
                                                    </div>
                                                )}

                                                {this.state.dadosDaConta && (
                                                    <div className="stats-number" style={{fontSize:"20px"}}>
                                                        <b style={{color:"#0043ff"}}>Agência:</b> {this.state.dadosDaConta[0].agency} 
                                                    </div>
                                                )}

                                                <div className="stats-desc mb-1">Banco: 461 - Asaas I.P S.A</div>

                                                {this.state.statusLoja && this.state.statusLoja.general && this.state.statusLoja.general === 'APPROVED' ? (
                                                    <span className="badge border border-green text-green px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                        <i className="fa fa-check fs-9px fa-fw me-5px"></i> Conta Ativa 
                                                    </span>
                                                ) : (
                                                    <span className="badge border border-danger text-danger px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                        <i className="fa fa-ban fs-9px fa-fw me-5px"></i> Conta Inativa, fale com o suporte
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-md-6">
                                        <div className="widget widget-stats ">
                                            <div className="stats-content">
                                                <div className="stats-title">Saldo Total</div>
                                                <div className="stats-number" style={{color: this.state.resumo.balanceData.balance >= 0 ? 'green' : 'red'}}>{this.state.resumo.balanceData.balance.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </div>
                                                
                                                {this.state.resumo.balanceData.balance >= 0 && (
                                                    <div className="alert alert-primary">Com a GRUB® você economizou <b>{(this.state.resumo.balanceData.balance * 0.095).toLocaleString('pt-br', {style: 'currency', currency: 'BRL'})}</b> em taxas.</div>
                                                )}

                                                <button className="btn btn-xs btn-default" onClick={(e) => this.toggleModalPix(e)}>Solicitar transferência</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-md-6">
                                        <div className="widget widget-stats ">
                                            <div className="stats-content">
                                                <div className="stats-title">Saldo a receber</div>
                                                <div className="stats-number" style={{color:"#f59c1a"}}>{this.state.resumo.statisticsData.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})} </div>

                                                <div className="stats-desc">Valores que serão creditados em sua conta.</div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className='row mb-3'>
                            <div className='col-12'>
                                <div className="btn-group"  style={{float:"left"}}>
                                    <button className={this.state.filterDates === "filtro1" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "filtro1")}>Últimos 7 dias</button>
                                    <button className={this.state.filterDates === "filtro2" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "filtro2")}>Últimos 15 dias</button>
                                    <button className={this.state.filterDates === "filtro3" ? "btn btn-white active" : "btn btn-white"} onClick={(e) => this.hadleFilterDate(e, "filtro3")}>Últimos 30 dias</button>
                                </div>

                                <div className="d-sm-flex align-items-center" style={{float:"right"}}>
                                    <DateRangePicker initialSettings={initialDatePicker} onApply={this.handleDateApplyEvent}>
                                        <button className="btn btn-white me-2 text-truncate">
                                            <i className="fa fa-calendar fa-fw text-opacity-50 ms-n1 me-1"></i> 
                                            <span>{this.state.dateRange && (this.state.dateRange.currentWeek)}</span>
                                            <b className="caret ms-1 opacity-5"></b>
                                        </button>
                                    </DateRangePicker>
                                </div>
                            </div>
                        </div>

                        <div className="card border-0">
                            <ul className="nav nav-tabs nav-tabs-v2 px-3">
                                {this.state.tabs.map( (item) =>
                                    <li key={item.chave} className="nav-item me-2" >
                                        <Link to={item.chave} data-filter={item.chave} onClick={this.aplicaFiltro} className={this.state.filtro === item.chave ? "nav-link px-2 active" : "nav-link px-2"}>
                                            {item.valor}
                                        </Link>
                                    </li>
                                )}
                            </ul>

                            <div className="card-body">
                                <div className="table-responsive mb-3">
                                    {this.state.list && this.state.list.length > 0 ? (
                                        <>
                                            {this.state.filtro === 'extrato' && (
                                                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{textAlign:"left"}}><b>ID Pedido</b></th>
                                                            <th style={{textAlign:"left"}}><b>Data</b></th>
                                                            <th style={{textAlign:"left"}}><b></b></th>
                                                            <th style={{textAlign:"left"}}><b>Descrição da transação</b></th>
                                                            <th style={{textAlign:"left"}}><b>Valor</b></th>
                                                            <th style={{textAlign:"left"}}><b>Saldo</b></th>
                                                            <th style={{textAlign:"left"}}><b></b></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>       
                                                        {this.state.list.map((item, i, arr) => 
                                                            <>
                                                                {item.type === "PAYMENT_RECEIVED" && i < arr.length-1 && (
                                                                    <tr>
                                                                        <td style={{textAlign:"left"}}>
                                                                            {item.pagamento && (
                                                                                <Link to="#" onClick={(e) => this.handleDetalhe(e, item)} className="fw-bold"> #{item.pagamento.id_comanda} </Link>
                                                                            )}
                                                                        </td>
                                                                        <td style={{textAlign:"left"}}>{item.date}</td>
                                                                        <td > <i style={{color:"#0043ff"}} className="fa-solid fa-arrow-right-long"></i> </td>
                                                                        <td >{item.description}</td>
                                                                        {item.type === "PAYMENT_RECEIVED" && item.comissao ? (
                                                                            <td style={{ color: '#204903', fontWeight:'bold' }}>{(parseFloat(item.value) + parseFloat(item.comissao)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                                        ):(
                                                                            <td style={{ color: '#204903', fontWeight:'bold' }}>{parseFloat(item.value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                                        )}
 
                                                                        <td style={{color: '#0043ff'}}>
                                                                            {this.getSaldo(i, arr).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                                                        </td>
                                                                        <td></td>       
                                                                    </tr>
                                                                )}

                                                                {item.type === "TRANSFER" && i < arr.length-1 && (
                                                                    <tr>
                                                                        <td style={{textAlign:"left"}}>
                                                                            {item.transferId && (
                                                                                <Link to="#" onClick={(e) => this.handleDetalheTransferencia(e, item)} className="fw-bold"> Ver mais </Link>
                                                                            )}
                                                                        </td>
                                                                        <td style={{textAlign:"left"}}>{item.date}</td>
                                                                        <td > <i style={{color:"#0043ff"}} className="fa-solid fa-arrow-right-long"></i> </td>
                                                                        <td >{item.description}</td>
                                                                        <td style={{ color: '#b22626', fontWeight:'bold' }}>{this.calcula(item, i, arr).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</td>
                                                                        <td style={{ color: '#0043ff'}}>
                                                                            {parseFloat(item.balance).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                                                                        </td>
                                                                        <td style={{textAlign:"left"}}></td>       
                                                                    </tr>
                                                                )}
                                                            </>
                                                        )}
                                                    </tbody>
                                                </table>
                                            )}

                                            {this.state.filtro === 'recebiveis' && (
                                                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{textAlign:"center"}}><b>ID Pedido</b></th>
                                                           
                                                            <th style={{textAlign:"center"}}><b>Tipo do Pagamento</b></th>
                                                            <th style={{textAlign:"center"}}><b>Data de Efetivaçao</b></th>
                                                            <th style={{textAlign:"center"}}><b>Origem</b></th>
                                                            <th style={{textAlign:"center"}}><b>Cliente</b></th>
                                                            <th style={{textAlign:"center"}}><b>Valor</b></th>
                                                            <th style={{textAlign:"center"}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>       
                                                        {this.state.list && this.state.list.length > 0 && this.state.list.map((item) => 
                                                            <tr>
                                                                <td style={{textAlign:"center"}}>
                                                                    <b style={{color:"#0043ff", cursor:"pointer"}} onClick={(e) => this.handleDetalhe(e, item)}>#{item.externalReference}</b>
                                                                </td>
                                                                
                                                                <td style={{textAlign:"center"}}><i style={{color:"#0043ff"}} className='fa-solid fa-credit-card'></i> {item.billingType === "CREDIT_CARD" ? "Cartão de credito" : "Pix"}</td>
                                                                <td style={{textAlign:"center"}}>{this.formatDate(item.estimatedCreditDate)}</td>
                                                                <td style={{textAlign:"center"}}>

                                                                {item.pedido && item.pedido.b_balcao ? (
                                                                    <span className="badge bg-primary text-white px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                                         Balcão
                                                                    </span>
                                                                ):(
                                                                    <span className="badge bg-primary text-white px-2 pt-5px pb-5px rounded fs-12px d-inline-flex align-items-center">
                                                                        <i className="fa-solid fa-motorcycle fs-9px fa-fw me-5px"></i> Delivery
                                                                    </span>
                                                                )}

                                                                </td>
                                                                <td style={{textAlign:"center"}}>{item.pedido && item.pedido.entrega ? item.pedido.entrega.nome : item.pedido ? item.pedido.nome_cliente : ''}</td>
                                                                <td style={{textAlign:"center"}}><b style={{color:"green"}}>{item.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                                                <td style={{textAlign:"center"}}></td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            )}

                                            {this.state.filtro === 'transferencia' && (
                                                <table className="table table-hover table-panel text-nowrap align-middle mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th style={{textAlign:"center"}}><b>Tipo do Pagamento</b></th>
                                                            <th style={{textAlign:"center"}}><b>Data de Efetivaçao</b></th>
                                                            <th style={{textAlign:"center"}}><b>Descrição</b></th>
                                                            <th style={{textAlign:"center"}}><b>Destino</b></th>
                                                            <th style={{textAlign:"center"}}><b>Valor</b></th>
                                                            <th style={{textAlign:"center"}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>       
                                                        {this.state.list && this.state.list.length > 0 && this.state.list.map((item) => 
                                                            <tr>
                                                                <td style={{textAlign:"center"}}><i style={{color:"#0043ff"}} className='fa-solid fa-credit-card'></i> {item.billingType === "CREDIT_CARD" ? "Cartão de credito" : "Pix"}</td>
                                                                <td style={{textAlign:"center"}}>{this.formatDate(item.confirmedDate)}</td>
                                                                <th style={{textAlign:"center"}}>{item.description}</th>
                                                                <td style={{textAlign:"center"}}>{item.bankAccount.ownerName}</td>
                                                                <td style={{textAlign:"center"}}><b style={{color:"green"}}>{item.value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></td>
                                                                <td style={{textAlign:"center"}}></td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            )}
                                        </>
                                    ) : (
                                        <div className="card border-0" style={{padding:"15px"}}>

                                            <Lottie animationData={lottiejson} loop={true} style={{width: "500px", margin:"auto"}} />
                                            <h3 style={{textAlign:"center"}}>Nenhuma transação encontrada no periodo selecionado. </h3>
                                        
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <Offcanvas show={this.state.showCanvas} onHide={() => this.setState({showCanvas: false})}  placement="end" style={{width:"40%"}}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                    <h3>Pedido: #{this.state.detalheId}</h3>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Detalhe id={this.state.detalheId} comissao={this.state.comissao}/>
                            </Offcanvas.Body>
                        </Offcanvas>

                        <Offcanvas show={this.state.showCanvasTransferencia} onHide={() => this.setState({showCanvasTransferencia: false})}  placement="end" style={{width:"40%"}}>
                            <Offcanvas.Header closeButton>
                                
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <DetalheTransferencia id={this.state.transferencia}/>
                            </Offcanvas.Body>
                        </Offcanvas>


                        <Modal isOpen={this.state.modalPix}>
                            <ModalHeader>Transferências para Contas de Mesma Titularidade</ModalHeader>
                            <ModalBody>
                                <p>Para sua segurança, transferências GRUB® só são permitidas para chaves PIX vinculadas ao mesmo CNPJ da conta titular. Certifique-se de que a chave PIX utilizada seja o CNPJ da empresa responsável pela conta. Transferências para outras chaves ou contas não serão aceitas.</p>

                                <div className="form-floating mb-15px">
                                    <InputText
                                        nome="CNPJ"
                                        dadosIniciais={this.state.user.loja.cnpj}
                                        readOnly={true}
                                    />
                                    <Label nome="CNPJ" label="PIX DESTINO"/>
                                </div>

                                <div className="form-floating mb-15px">
                                    <CurrencyInput
                                        nome="valor"
                                        className="form-control fs-15px" 
                                        onChange={this.setValor}
                                    />
                                    <Label nome="valor" label="Valor a ser tranferido"/>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button className="btn btn-primary" data-mesa={null} onClick={(e) => this.enviarPix(e)}>ENVIAR</button>
                                <button className="btn btn-white" data-mesa={null} onClick={(e) => this.toggleModalPix(e)}>CANCELAR</button>
                            </ModalFooter>
                        </Modal>
                    
                    </div>
                ) : (
                    <div className="card border-0" style={{ textAlign:'center'}}>
                        <Lottie animationData={lottieplaceholder} loop={true} style={{width: "50%", paddingTop:"-40px",margin:"auto"}} />
                        <h2>Carregando as informações da sua conta Meu Dinheiro.</h2>
                        
                    </div>
                )}
            </>
        )
    }

}
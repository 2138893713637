import React from 'react'
import { getRelatorio } from './../../store/api.js'
import PrintStyles from './../../scss/print.css';

export default class RelatorioVendas extends React.Component {
     constructor(props) {
        super(props)
        
        this.state = {
            totalVendas: null, 
            categorias: null,
            vendasMap:null,
            di: null,
            df: null
        }
     }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const di = params.get('di');
        const df = params.get('df');

        getRelatorio(di, df).then(result => {
            
            const vendasMap = result.vendaProduto.reduce((acc, venda) => {
                acc[venda.id] = venda;
                return acc;  
            }, {});

            const t1 = new Date(di+"T00:00:00");
            const t2 = new Date(df+"T00:00:00");

            this.setState({totalVendas: result.totalVendas, categorias: result.categorias, vendasMap: vendasMap, di: t1.getDate()+" / "+(t1.getMonth()+1)+" / "+t1.getFullYear(), df: t2.getDate()+" / "+(t2.getMonth()+1)+" / "+t2.getFullYear() })
        })
    }
     
    render(){
        return (
            <div className="tab-content panel p-5 rounded-0 rounded-bottom">
                {this.state.totalVendas && (
                    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
                        <div className='row' style={{position:"relative"}}>
                            <div className='col-6'>
                                <h2>Relatório de Vendas</h2>
                                <p><strong>Periodo:</strong> {this.state.di} - {this.state.df} </p>
                                <p><strong>Total Vendido:</strong> R$ {this.state.totalVendas.toFixed(2)}</p>
                            </div>
                            <div className='col-6'>
                                <img src="https://grubicons.s3.amazonaws.com/logo.svg" width="100px" style={{position:"absolute", right:0}} />
                            </div>
                        </div>

                        {this.state.categorias.map((categoria) => {
                            // Calcula o total de vendas da categoria
                            const totalCategoria = categoria.produto.reduce((sum, produto) => {
                                return sum + (this.state.vendasMap[produto.id]?.valorTotal || 0);
                            }, 0);
                    
                            return (
                                <div key={categoria.id} style={{ marginBottom: "20px" }}>
                                <h3>{categoria.nome} - Total: R$ {totalCategoria.toFixed(2)}</h3>
                    
                                <table border="1" cellPadding="10" style={{ width: "100%", borderCollapse: "collapse" }}>
                                    <thead>
                                    <tr>
                                        <th>Produto</th>
                                        <th>Valor Total</th>
                                        <th>Quantidade</th>
                                        <th>Status</th>
                                        <th>% do Total Geral</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {categoria.produto.map((produto) => {
                                        const venda = this.state.vendasMap[produto.id] || { valorTotal: 0 };
                                        const percentual = ((venda.valorTotal / this.state.totalVendas) * 100).toFixed(2);
                    
                                        return (
                                        <tr key={produto.id}>
                                            <td>{produto.nome}</td>
                                            <td>R$ {venda.valorTotal.toFixed(2)}</td>
                                            <td> {venda.quantidade ? venda.quantidade : 0}</td>
                                            <td> {produto.deleted_at ? "Eliminado" : "Vigente"}</td>
                                            <td>{percentual}%</td>
                                        </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                                </div>
                            );
                        })}

                        <button className="no-print btn btn-primary" onClick={() => window.print()}>
                            Imprimir Relatório
                        </button>
                    </div>
                )}
            </div>
        )
    }
  }
  